<template>
    <b-card title="Daily DashBoard">
        <b-row>
            <b-col md="4">
                <b-form-group label="Store Name">
                    <v-select v-model="storeName" @input="selectStoreName" label="storeName"
                        :options="storeNameOptions"></v-select>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <b-tabs>
            <b-tab active title="Urgent Order">
                <b-row>
                    <b-col md=12>
                        <div>
                            <!-- search input -->
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div class="d-flex align-items-center">
                                        <label class="mr-1">Search</label>
                                        <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                            class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </div>

                            <!-- table -->
                            <vue-good-table :line-numbers="true" :columns="columns" :rows="urgentRows" :rtl="direction"
                                :search-options="{
                                    enabled: true,
                                    externalQuery: searchTerm
                                }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                                <template slot="table-row" slot-scope="props">

                                    <!-- Column: Name -->
                                    <span v-if="props.column.field === 'dueDate'" class="text-nowrap">
                                        <span class="text-nowrap">{{
                                            convertTimeStampToTime(props.row.dueDate)
                                        }}</span>
                                    </span>


                                    <span v-else-if="props.column.field === 'orderNo'">
                                        <b-link v-b-toggle.sidebar-right-orderDetail @click="getOrderDetail(props.row.id)">
                                            {{ '#' + props.row.orderNo }}
                                        </b-link>
                                    </span>

                                    <!-- Column: Status -->
                                    <span v-else-if="props.column.field === 'customerName'">
                                        <p>
                                            {{ props.row.customerName }}
                                        </p>
                                        <p>
                                            {{ props.row.customerMobileNo }}
                                        </p>
                                    </span>

                                    <!-- Column: Common -->
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>

                                <!-- pagination -->
                                <template slot="pagination-bottom" slot-scope="props">
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="d-flex align-items-center mb-0 mt-1">
                                            <span class="text-nowrap ">
                                                Showing 1 to
                                            </span>
                                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                                class="mx-1"
                                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                                        </div>
                                        <div>
                                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                                first-number last-number align="right" prev-class="prev-item"
                                                next-class="next-item" class="mt-1 mb-0"
                                                @input="(value) => props.pageChanged({ currentPage: value })">
                                                <template #prev-text>
                                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                                </template>
                                            </b-pagination>
                                        </div>
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Delivery Order">
                <b-row>
                    <b-col md=12>
                        <div>
                            <!-- search input -->
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div class="d-flex align-items-center">
                                        <label class="mr-1">Search</label>
                                        <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                            class="d-inline-block" />
                                    </div>
                                </b-form-group>
                            </div>

                            <!-- table -->
                            <vue-good-table :line-numbers="true" :columns="columns" :rows="deliverRows" :rtl="direction"
                                :search-options="{
                                    enabled: true,
                                    externalQuery: searchTerm
                                }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                                <template slot="table-row" slot-scope="props">

                                    <!-- Column: Name -->
                                    <span v-if="props.column.field === 'dueDate'" class="text-nowrap">
                                        <span class="text-nowrap">{{
                                            convertTimeStampToTime(props.row.dueDate)
                                        }}</span>
                                    </span>


                                    <span v-else-if="props.column.field === 'orderNo'">
                                        <b-link v-b-toggle.sidebar-right-orderDetail @click="getOrderDetail(props.row.id)">
                                            {{ '#' + props.row.orderNo }}
                                        </b-link>
                                    </span>

                                    <!-- Column: Status -->
                                    <span v-else-if="props.column.field === 'customerName'">
                                        <p>
                                            {{ props.row.customerName }}
                                        </p>
                                        <p>
                                            {{ props.row.customerMobileNo }}
                                        </p>
                                    </span>

                                    <!-- Column: Common -->
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>

                                <!-- pagination -->
                                <template slot="pagination-bottom" slot-scope="props">
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="d-flex align-items-center mb-0 mt-1">
                                            <span class="text-nowrap ">
                                                Showing 1 to
                                            </span>
                                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                                class="mx-1"
                                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                                        </div>
                                        <div>
                                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                                first-number last-number align="right" prev-class="prev-item"
                                                next-class="next-item" class="mt-1 mb-0"
                                                @input="(value) => props.pageChanged({ currentPage: value })">
                                                <template #prev-text>
                                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                                </template>
                                            </b-pagination>
                                        </div>
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Upcoming Birthday / Anniversary">
                <b-row>
                    <b-col>
                        <b-card no-body class="card-browser-states border">
                            <b-card-header>
                                <div>
                                    <b-card-title>Upcoming Birthday</b-card-title>
                                </div>
                            </b-card-header>

                            <!-- body -->
                            <b-card-body class="card-height">

                                <div v-for="(birthday) in birthdayAnniversary.recentBirthdys" :key="birthday.customerId"
                                    class="browser-states">
                                    <b-media no-body>
                                        <b-media-aside class="mr-1">
                                            <b-img width="50px" :src="convertImage(birthday.profilePhoto)"
                                                alt="browser img" />
                                        </b-media-aside>
                                        <b-media-body>
                                            <h5 class="align-self-center my-auto">
                                                {{ birthday.customerName }}
                                            </h5>
                                            <p class="align-self-center my-auto">
                                                {{ birthday.mobileNo }}<span class="align-self-center my-auto">
                                                    ({{ convertTimeStampToDate(birthday.birthdayDate) + ')' }}
                                                </span>
                                            </p>
                                        </b-media-body>
                                    </b-media>
                                    <div class="d-flex align-items-center">
                                        <b-button size="sm">Send Wishes</b-button>
                                    </div>
                                </div>
                            </b-card-body>
                            <!--/ body -->
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card no-body class="card-browser-states border">
                            <b-card-header>
                                <div>
                                    <b-card-title>Upcoming Anniversary</b-card-title>
                                </div>
                            </b-card-header>

                            <!-- body -->
                            <b-card-body class="card-height">

                                <div v-for="anniversary in birthdayAnniversary.recentAnniversarys"
                                    :key="anniversary.customerId" class="browser-states">
                                    <b-media no-body>
                                        <b-media-aside class="mr-1">
                                            <b-img width="50px" :src="convertImage(anniversary.profilePhoto)"
                                                alt="browser img" />
                                        </b-media-aside>
                                        <b-media-body>
                                            <h5 class="align-self-center my-auto">
                                                {{ anniversary.customerName }}
                                            </h5>
                                            <p class="align-self-center my-auto">
                                                {{ anniversary.mobileNo }}<span class="align-self-center my-auto">
                                                    ({{ convertTimeStampToDate(anniversary.anniversaryDate) + ')' }}
                                                </span>
                                            </p>
                                        </b-media-body>
                                    </b-media>
                                    <div class="d-flex align-items-center">
                                        <b-button size="sm">Send Wishes</b-button>
                                    </div>
                                </div>
                            </b-card-body>
                            <!--/ body -->
                        </b-card>
                    </b-col>

                </b-row>
            </b-tab>
        </b-tabs>
        <b-sidebar id="sidebar-right-orderDetail" width="95%" right shadow bg-variant="white" backdrop>
            <OrderDetail ref="orderDetail" />
        </b-sidebar>
    </b-card>
</template>
<script>
import {
    VBToggle, BCard, BRow, BTabs, BLink, BSidebar, BTab, BCol, BButton, BFormGroup, BFormSelect, BImg, BMedia, BPagination, BFormInput, BCardBody, BCardHeader, BCardTitle, BMediaAside, BMediaBody
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import OrderDetail from '../order/OrderDetail.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BRow, VBToggle,
        BCol, BLink, BSidebar,
        BButton, BTabs, BTab, BMediaAside, BMediaBody, BMedia, BImg, vSelect, BFormGroup,
        BCard, VueGoodTable, BFormSelect, BPagination, BFormInput, BCardBody, BCardHeader, BCardTitle, OrderDetail
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                {
                    label: 'Due Time',
                    field: 'dueDate',
                },
                {
                    label: 'Customer Details',
                    field: 'customerName',
                },

                {
                    label: 'Total Pcs',
                    field: 'totalPices',
                },
                {
                    label: 'Garments',
                    field: 'totalItems',
                },
                {
                    label: 'Net Amount',
                    field: 'totalNetAmount',
                },
                {
                    label: 'Paid',
                    field: 'totalPayedAmount',
                },
                {
                    label: 'Balance',
                    field: 'totalDueAmount',
                },


            ],
            customerId: 0,
            urgentRows: [],
            deliverRows: [],
            searchTerm: '',
            birthdayAnniversary: {},
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },


        }
    },
    methods: {
        convertTimeStampToDateWithoutTime(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY');
            } else {
                return '';
            }
        },
        convertImage(photo) {
            if (photo == null) {
                return require('@/assets/images/avatars/placeholderImage.png');
            } else {
                return 'data:image/*;base64,' + photo;
            }

        },

        convertTimeStampToDate(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD/MM/YYYY');
            }
        },

        convertTimeStampToTime(value) {
            if (value) {
                return moment.utc(value).format('hh:mm A');
            } else {
                return '';
            }
        },

        convertTimeStampToDateForDueDate(value) {
            if (value) {
                return moment.utc(value).format('YYYY-MM-DD');
            } else {
                return '';
            }
        },

        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        selectStoreName() {
            this.getDailyDashboardDetails();
        },
        getDailyDashboardDetails() {
            let self = this;
            var axios = require('axios');
            var data = JSON.stringify(
                {
                    "storeId": self.storeName.id,
                }
            );
            // alert(data)
            axios(api.getApi('put', '/orders/getOrdersFilteredList', data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));

                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index].urgent1 || response.data[index].urgent2) {
                            self.urgentRows.push(response.data[index]);
                        } else {
                            self.deliverRows.push(response.data[index]);
                        }

                    }
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getOrderDetail(orderId) {
            let self = this;
            self.$refs.orderDetail.getOrderDetail(orderId);
        },

        getBirthdayAnniversaryData() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/users/getDaskboardData', data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.birthdayAnniversary = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },

    },
    created() {
        this.getDailyDashboardDetails();
        this.getBirthdayAnniversaryData();
        this.getStoreName();
    }

}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>

